/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './App.css';

function App() {
  const printerType = ['Select Printer...'];
  const printerMode = ['Generic'];
  const paperSizes = ['A4'];
  const [printerTypes, setPrinterType] = useState(printerType);
  const [ipAddress, setIpAddress] = useState('');
  const [printing, setPrinting] = useState(false);
  const [loadPrint, setLoadPrint] = useState(false);
  const [printFailed, setPrintFailed] = useState(false);
  const [printSuccess, setPrintSuccess] = useState(false);
  const [droppedImage, setDroppedImage] = useState(null);
  const [droppedFileName, setDroppedFileName] = useState('No file selected');
  const [files, setFiles] = useState();

  //getPrinters
  const getPrinters = () => {
    setLoadPrint(true);
    const printip = `http://${ipAddress}:5577/getPrinters`
    console.log(printip);
    fetch(printip)
      .then((response) => response.json())
      .then((data) => {
        const datas = data.data.printer
        console.log(datas[0].name);
        const name = datas.map(item => item.name);
        console.log(name);
        setPrinterType(name); 
        setLoadPrint(false);
      })
      .catch((error) => {
        alert('Error: Check IP address anda', error);
        setLoadPrint(false);
        console.error('Error:', error);
      });
  }

  //Post Print
  const postPrint = () => {
    setPrinting(true);
    setPrintFailed(false);
    setPrintSuccess(false);

    if(files == null){
      alert("File Kosong!");
      setPrintFailed(true);
      setPrinting(false);
      return;
    }else if(ipAddress === ''){
      alert("Ip Address Kosong!");
      setPrintFailed(true);
      setPrinting(false);
      return;
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        printer: document.getElementById('printerType').value,
        data : [files.replace('data:application/pdf;base64,','')]
    })};

    fetch(`http://${ipAddress}:5577/printGeneric`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setPrintSuccess(true);
        setPrinting(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setPrintFailed(true);
        setPrinting(false);
      });
  }
  

  // useEffect(() => {
  //   getPrinters();
  // }, []);



  const handleIpAddressChange = (event) => {
    setIpAddress(event.target.value);
  };


  const handleFileDrop = (event) => {
    event.preventDefault();
    const files = event.target.files || event.dataTransfer.files;

    // Hanya memproses file pertama yang dijatuhkan
    const file = files[0];

    const fileExtension = file.name.split('.').pop().toLowerCase();
    if (fileExtension !== 'pdf') {
      alert('Hanya file PDF');
      return;
    }

    setDroppedFileName(file.name);

    if (file.type === 'application/pdf') {
      // Jika file adalah PDF, set img src ke '/pdf.png'
      setDroppedImage('/pdf.png');
    } else {
      setDroppedImage('/doc.png');
    }

    // Membuat objek FileReader untuk membaca file gambar
    const reader = new FileReader();

    // Menangani peristiwa ketika file selesai dibaca
    reader.onload = (e) => {
      setFiles(e.target.result);
      console.log(e.target);
    };

    // Membaca file sebagai URL data gambar
    reader.readAsDataURL(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleChooseFromFile = (event) => {
    handleFileDrop(event);
  };

  return (
    <div className="App">
    <Helmet >
        <meta name="description" content="A printing application." data-react-helmet="true" />
        <meta http-equiv="Content-Security-Policy" content="default-src 'self'; connect-src *;"></meta>
        <meta name="referrer" content="no-referrer" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="Thunderlabs" />
    </Helmet>
      <body className="App-body">
      <img src="/logo.png" className="App-logo" alt="logo" />
        <div className="tutorial">
        <p>1. Unggah File Anda</p>
        </div>
        <div className="file-drop" onDrop={handleFileDrop} onDragOver={handleDragOver}>
          {droppedImage ? (
            <div>
              <img src={droppedImage} alt="Dropped image" />
              <p>{droppedFileName}</p>
            </div>
          ) : (
            <div>
              <p>Drop File Disini</p>
              <p>Atau</p>
              <input type="file" onChange={handleChooseFromFile} style={{ display: 'none' }} accept=".pdf" />
              <button onClick={() => document.querySelector('.file-drop input[type="file"]').click()}>Pilih File dari Perangkat anda</button>
            </div>
          )}
          
        </div>
        <div className="tutorial">
        <p>2. Masukkan IP Address dan Tekan "Check IP Address" untuk Menemukan Printer</p>
        </div>
        <div className="controls">
        <input type="text" placeholder="Input Ip Address" value={ipAddress} onChange={handleIpAddressChange} />
        {loadPrint ? (
            <div className="loader"></div>
          ) : (
            <button onClick={getPrinters}>Check IP Address</button>
          )}
        </div>
        <div className="tutorial">
        <p>3. Pilih Printer dan Tekan Tombol Print untuk Mencetak</p>
        </div>
        <div className="controls">
          <select id="printerType">
            {printerTypes.map((type, index) => (
              <option key={index} value={type}>{type}</option>
            ))}
          </select>
          <select id="printerMode">
            {printerMode.map((mode, index) => (
              <option key={index} value={mode}>{mode}</option>
            ))}
          </select>
          <select id="paperSize">
            {paperSizes.map((size, index) => (
              <option key={index} value={size}>{size}</option>
            ))}
          </select>
          
          {printing ? (
            <div className="loader"></div>
          ) : (
            <button onClick={postPrint}>Print</button>
          )}
        </div>
        {printing && <p style={{ color: 'white' }}>Print Dalam Proses...</p>}
        {printSuccess && <p style={{ color: 'greenyellow' }}>Print Sukses</p>}
        {printFailed && <p style={{ color: 'salmon' }}>Print Gagal, Silahkan coba lagi</p>}
      </body>
    </div>
  );
}

export default App;
